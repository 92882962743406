import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // 检测用户当前使用的语言
    // 文档: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // 注入 react-i18next 实例
    .use(initReactI18next)
    // 初始化 i18next
    // 配置参数的文档: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    "App11Loading" :"Loading # {{id}}",

                    "AppName": "META NFT Collection",
                    "Alert1": "Please put NFT ID in slot {{idx}}",
                    "Alert2": "NFT {{idx}} is not the same pattern as the first NFT",
                    "Alert3": "ID {{id}} is duplicated",
                    "Alert4": "ID {{id}} is duplicated with the existing color",
                    "Alert5": "ID {{id}} has been used in the last  {{days}} days",
                    "Alert6": "Add failed, please check if NFT #{{id}} has been destroyed.",
                    "SubmitSuccess": "Submit success",
                    "SubmitFail": "Submit fail",

                    "TotalReward": "Total Reward",
                    "RewardIssued": "Rewards Issued",
                    "EveryReward": "Every Reward",

                    "Note1": "Collect META NFTs, collect a full set of 6 colors of the same pattern to claim META rewards. If the full set is all winged, you can claim 6 times the rewards.",
                    "Note2": "Please add the META NFTs collected in this wallet to the slot.",
                    "Note3": "If the wrong NFT is added, you can click the slot to change.",

                    "CheckButton": "Verify and Claim META",

                    "Rule1": "1. Collect META NFTs, collect a full set of 6 colors of the same pattern to claim META rewards.",
                    "Rule2": "2. If the full set is all winged, you can claim 6 times the rewards.",
                    "Rule3": "3. View and record 6 NFT IDs in a Web3 wallet or blockchain browser.",
                    "Rule4": "4. Add the NFT ID to the slot, verify and pay the GAS fee, and the rewards will be sent to your wallet.",
                    "Rule5": "5. The total prize pool of the collection event is 15,000,000 META, which is evenly distributed in 4 periods, and 3,750,000 META is issued in each period.",
                    "Rule6": "6. The amount of each claim in the 1st/2nd/3rd/4th period is 8000/6000/4000/2000 META.",
                    "Rule7": "7. After claiming the rewards, META NFTs can be freely traded.",
                    "Rule8": "8. Each NFT ID can only be used once within {{days}} days.",

                    "ResultMessage1": "You will receive ",
                    "ResultMessage2": "View progress on Bscscan",
                    "ResultMessage3": "You can open the blockchain browser to view the transaction",

                    "BoardError1": "NFT ID does not exist",
                    "BoardError2": "NFT ID {{id}} does not belong to the current wallet address",
                    "BoardError3": "NFT ID {{id}} has been used, please use it again after the specified time",
                    "BoardError4": "NFT ID {{id}} verification failed: ",
                    "BoardError5": "Please input NFT ID",

                    "BoardError6": "Can only be used once within {{tDays}} days. Please wait {{days}} days {{hours}} hours {{minutes}} minutes to use NFT #{{id}} again.",

                    "RuleTitle": "META Collection Rules",

                    "App11Infomation": "Collect META NFTs, collect a full set of five attributes of 'Fire, Ice, Wind, Light, Dark' to claim META token airdrop. If the full set is all famale, airdrop x3 times.",
                    "App11Synthesize": "Claim Airdrop",
                    "App11PlusMetaNFT": "+ META NFTs",

                    "App11Error1": "There are not enough NFTs that meet the conditions in your wallet",
                    "App11Error21": "Invitation code error",

                    "App11Prompt1": "Please enter the invitation code",
                    "App11Label1": "My inviter",

                    'App11Label2': 'Please fill in the invitation code',
                    "App11Label3": 'After filling in the invitation code, you can participate in "collect and receive airdrops". It cannot be changed after filling in.',

                    "App11Label4": "My invitation code",
                    "App11Label5": "Not yet obtained",

                    "App11Tip1": "You can get",
                    "App11Tip2": "After receiving the airdrop, you will get an exclusive invitation code, which can invite friends to participate in the collection to get rewards.",
                    "App11Tip3": "Invitation reward rules",

                    "App11Tip4": "When the account using your invitation code receives the airdrop, you will get a 10% reward.",
                    "App11Tip5": "I invited",

                    "App11InviteButton": "Invite",

                    "App11Label6": "Confirm",
                    "App11Label7": "Cancel",
                    "App11Label8": "Please enter the invitation code",

                    "App1Rule": "1. Collect META NFTs, collect a full set of five attributes of 'Fire, Ice, Wind, Light, Dark' to claim META token airdrop.\n \
                    2. If the full set is all famale, you can claim 3 times the airdrop.\n \
                    3. The total prize pool of the collection event is 90,000,000 META, which is evenly distributed in 4 periods, and 22,500,000 META is airdropped in each period.\n \
                    4. In the 1st/2nd/3rd/4th period, the daily airdrop amount is 450/360/280/230 META, with a random change within the range of +-10%.\n \
                    5. After receiving the airdrop, META NFTs can be freely traded.\n \
                    6. Each NFT ID can only be used once every 24 hours.",

                    "App1Alert1": "Please bind the referrer first",

                    "App2InfoSuccess": "Hatching success, the NFT will be sent to your address later, please check it later.",
                    "App2ClaimButton": "Claim",

                    "App2Health": "HP",
                    "App2AddTab1": '1. Add NFT',
                    "App2AddTab2": '2. Add Meta',
                    "App2AddTab3": '3. Confirm',
                    "App2ErrorExists": "There is an egg hatching in the current slot, and a new NFT cannot be added temporarily.",
                    "App2LabelSelectToken": "Please select the NFT in the wallet",
                    "App2ButtonNext": "Next",
                    "App2ButtonApproveNFT": "Approve NFT",
                    "App2ButtonApproveMETA": "Approve Meta",
                    "App2AddHatchingTip": "During the hatching process, it cannot be interrupted or returned until the end.",
                    "App2ConfirmToHatching": "Confirm Hatching",
                    "App2TxSubmit": "Transaction submitted",

                    "App11Loading" : "Loading #{{id}}",

                    "App2AddMetaTip": "Consume {{meta}} META, complete in 24 hours",



                }
            },
            id: {
                // 印尼语翻译
                translation: {

                    "AppName": "Koleksi META NFT",
                    "Alert1": "Masukkan NFT ID ke slot {{idx}}",
                    "Alert2": "NFT {{idx}} tidak memiliki pola yang sama dengan NFT pertama",
                    "Alert3": "ID {{id}} sudah ada",
                    "Alert4": "ID {{id}} sudah ada dengan warna yang ada",
                    "Alert5": "ID {{id}} sudah digunakan dalam {{days}} hari terakhir",
                    "Alert6": "Penambahan gagal, silakan periksa apakah NFT #{{id}} sudah dihancurkan.",

                    "SubmitSuccess": "Berhasil mengirim",
                    "SubmitFail": "Gagal mengirim",

                    "TotalReward": "Total Hadiah",
                    "RewardIssued": "Hadiah Diterbitkan",
                    "EveryReward": "Setiap Hadiah",

                    "Note1": "Kumpulkan META NFT, kumpulkan set lengkap 6 warna dengan pola yang sama untuk klaim hadiah META. Jika set lengkap semuanya berwarna sayap, Anda bisa klaim 6 kali lipat hadiah.",
                    "Note2": "Tambahkan META NFT yang dikumpulkan di dompet ini ke slot.",

                    "Note3": "Jika NFT yang salah ditambahkan, Anda dapat mengklik slot untuk mengganti.",
                    "CheckButton": "Verifikasi dan Klaim META",

                    "Rule1": "1. Kumpulkan META NFT, kumpulkan set lengkap 6 warna dengan pola yang sama untuk klaim hadiah META.",
                    "Rule2": "2. Jika set lengkap semuanya berwarna sayap, Anda bisa klaim 6 kali lipat hadiah.",
                    "Rule3": "3. Lihat dan catat 6 NFT ID di dompet Web3 atau browser blockchain.",

                    "Rule4": "4. Tambahkan NFT ID ke slot, verifikasi dan bayar biaya GAS, dan hadiah akan dikirim ke dompet Anda.",
                    "Rule5": "5. Total hadiah koleksi adalah 15.000.000 META, yang didistribusikan secara merata dalam 4 periode, dan 3,750,000 META diterbitkan pada setiap periode.",

                    "Rule6": "6. Jumlah klaim setiap periode 1/2/3/4 adalah 8000/6000/4000/2000 META.",
                    "Rule7": "7. Setelah klaim hadiah, META NFT dapat diperdagangkan secara bebas.",
                    "Rule8": "8. Setiap NFT ID hanya dapat digunakan sekali dalam {{days}} hari.",
                    "ResultMessage1": "Anda akan menerima ",
                    "ResultMessage2": "Lihat progres di Bscscan",
                    "ResultMessage3": "Anda dapat membuka browser blockchain untuk melihat transaksi",
                    "BoardError1": "NFT ID tidak ada",
                    "BoardError2": "NFT ID {{id}} tidak milik alamat dompet saat ini",
                    "BoardError3": "NFT ID {{id}} sudah digunakan, gunakan lagi setelah waktu yang ditentukan",
                    "BoardError4": "Verifikasi NFT ID {{id}} gagal: ",
                    "BoardError5": "Silakan masukkan NFT ID",

                    "RuleTitle": "Aturan Koleksi META",

                    "BoardError6": "Hanya dapat digunakan sekali dalam {{tDays}} hari. Harap tunggu {{days}} hari {{hours}} jam {{minutes}} menit untuk menggunakan NFT #{{id}} lagi.",
                    "App11Infomation": "Kumpulkan META NFT, kumpulkan set lengkap lima atribut 'Api, Es, Angin, Cahaya, Gelap' untuk klaim airdrop token META. Jika set lengkap semuanya adalah ibu, airdrop x3 kali.",
                    "App11Synthesize": "Klaim Airdrop",
                    "App11PlusMetaNFT": "+ META NFTs",

                    "App11Error1": "Tidak ada cukup NFT yang memenuhi syarat di dompet Anda",
                    "App11Error21": "Kode undangan salah",

                    "App11Prompt1": "Silakan masukkan kode undangan",
                    "App11Label1": "Pengundang saya",

                    'App11Label2': 'Silakan isi kode undangan',
                    "App11Label3": 'Setelah mengisi kode undangan, Anda dapat berpartisipasi dalam "mengumpulkan dan menerima airdrop". Tidak dapat diubah setelah diisi.',
                    "App11Label4": "Kode undangan saya",
                    "App11Label5": "Belum diperoleh",
                    "App11Tip1": "Anda dapat mendapatkan",
                    "App11Tip2": "Setelah menerima airdrop, Anda akan mendapatkan kode undangan eksklusif, yang dapat mengundang teman untuk berpartisipasi dalam pengumpulan untuk mendapatkan hadiah.",

                    "App11Tip3": "Aturan hadiah undangan",
                    "App11Tip4": "Ketika akun menggunakan kode undangan Anda menerima airdrop, Anda akan mendapatkan 10% hadiah.",
                    "App11Tip5": "Saya mengundang",

                    "App11InviteButton": "Undang",
                    "App11Label6": "Konfirmasi",
                    "App11Label7": "Batal",
                    "App11Label8": "Silakan masukkan kode undangan",

                    "App1Rule": "1. Kumpulkan META NFT, kumpulkan set lengkap lima atribut 'Api, Es, Ang in, Cahaya, Gelap' untuk klaim airdrop token META.\n \
                    2. Jika set lengkap semuanya adalah ibu, Anda bisa klaim 3 kali lipat airdrop.\n \
                    3. Total hadiah koleksi adalah 90.000.000 META, yang didistribusikan secara merata dalam 4 periode, dan 22.500.000 META di airdrop pada setiap periode.\n \
                    4. Dalam periode 1/2/3/4, jumlah airdrop harian adalah 450/360/280/230 META, dengan perubahan acak dalam kisaran +-10%.\n \
                    5. Setelah menerima airdrop, META NFT dapat diperdagangkan secara bebas.\n \
                    6. Setiap NFT ID hanya dapat digunakan sekali setiap 24 jam.",

                    "App1Alert1": "Silakan bind referer terlebih dahulu",

                    "App11Loading" : "Memuat #{{id}}",

                    "App2InfoSuccess": "Pemeliharaan berhasil, NFT akan dikirim ke alamat Anda nanti, silakan cek nanti.",
                    "App2ClaimButton": "Klaim",
                    "App2Health": "HP",
                    "App2AddTab1": '1. Tambahkan NFT',
                    "App2AddTab2": '2. Tambahkan Meta',
                    "App2AddTab3": '3. Konfirmasi Pemeliharaan',
                    "App2ErrorExists": "Ada telur menetas di slot saat ini, dan NFT baru tidak dapat ditambahkan sementara.",
                    "App2LabelSelectToken": "Silakan pilih NFT di dompet",
                    "App2ButtonNext": "Berikutnya",
                    "App2ButtonApproveNFT": "Setujui NFT",
                    "App2ButtonApproveMETA": "Setujui Meta",
                    "App2AddHatchingTip": "Selama proses pemeliharaan, itu tidak dapat dihentikan atau dikembalikan hingga akhir.",
                    "App2ConfirmToHatching": "Konfirmasi Pemeliharaan",

                    "App2AddMetaTip": "Konsumsi {{meta}} META, selesai dalam 24 jam",

                }
            },
            cn: {
                translation: {
                    "AppName": "META NFT 集卡",
                    "Alert1": "请在第{{idx}}卡槽放入NFT ID",
                    "Alert2": "第{{idx}}个NFT与第一个NFT不是同一种花纹",
                    "Alert3": "ID {{id}} 重复了",
                    "Alert4": "ID {{id}} 与已有的颜色重复了",
                    "Alert5": "'ID {{id}} 在最近{{days}}天内已经使用过了",
                    "Alert6": "添加失败，请检查NFT  #{{id}} 是否已销毁。",
                    "SubmitSuccess": "提交成功",
                    "SubmitFail": "提交失败",

                    "TotalReward": "总奖励",
                    "RewardIssued": "已发放",
                    "EveryReward": "每次奖励",

                    "Note1": "收集META NFTs，集齐同一种花纹的全套6种颜色，即可领取META收益。如果全套都有翅膀，收益×6倍。",
                    "Note2": "请将这个钱包里收藏的META NFTs添加到卡槽里。",
                    "Note3": "如果添加错误的NFT，可以点击卡槽更换。",
                    "CheckButton": "验证并领取META",

                    "Rule1": "1、收集META NFTs，集齐同一种花纹的全套6种颜色，即可领取META收益。",
                    "Rule2": "2、如果全套都是有翅膀的，将可领取6倍收益。",
                    "Rule3": "3、在Web3钱包，或者区块链浏览器内查看并记录6个NFT ID。",
                    "Rule4": "4、将NFT ID添加到卡槽内，验证并支付GAS费后，收益将发送到您的钱包。",
                    "Rule5": "5、集卡活动总奖池15,000,000META，平均分为4期，每期发放375万META。    ",
                    "Rule6": "6、第1/2/3/4期，每次领取金额分别为8000/6000/4000/2000META。",
                    "Rule7": "7、领取收益后，META NFTs可以自由交易。",
                    "Rule8": "8、每个NFT ID在{{days}}天内仅可以搭配使用一次。",

                    "ResultMessage1": "您将获得 ",
                    "ResultMessage2": "在Bscscan查看进度",
                    "ResultMessage3": "可以打开区块链浏览器查看交易",

                    "BoardError1": "NFT ID不存在",
                    "BoardError2": "NFT ID {{id}} 不属于当前钱包地址",
                    "BoardError3": "NFT ID {{id}} 已经使用过了，请在规定时间后再次使用",
                    "BoardError4": "NFT ID {{id}} 验证失败: ",
                    "BoardError5": "请输入NFT ID",

                    "BoardError6": "{{tDays}}天内仅可使用一次。请等待{{days}} 天{{hours}}小时{{minutes}}分钟后再次使用NFT #{{id}}。",

                    "RuleTitle": "META 集卡规则",

                    "App11Infomation": "收集META NFTs，集齐全套“火冰风光暗”五种属性，即可领取META代币空投。如果全套为母，则空投x3倍。",
                    "App11Synthesize": "领取空投",
                    "App11PlusMetaNFT": "+ META NFTs",

                    "App11Error1": "您的钱包中已经没有足够的符合条件的NFT了",
                    "App11Error21": "邀请码错误",

                    "App11Prompt1": "请输入邀请码",
                    "App11Label1": "我的邀请人",

                    'App11Label2': '请填写邀请码',
                    "App11Label3": '填入邀请码后，才能参与“收集领空投”。填入后不可更改。',

                    "App11Label4": "我的邀请码",
                    "App11Label5": "暂未获得",
                    "App11Tip1": "首次领空投后可获得",

                    "App11Tip2": "领取空投后，你将获得专属邀请码，可以邀请好友参加收集获得奖励。",
                    "App11Tip3": "邀请奖励规则",

                    "App11Tip4": "当使用你的邀请码的账户，领取空投时，你将获得10%奖励。",
                    "App11Tip5": "我邀请的",

                    "App11InviteButton": "邀请",

                    "App11Label6": "确认",
                    "App11Label7": "取消",
                    "App11Label8": "请输入邀请码",

                    "App1Rule": "1、收集META NFTs，集齐全套“火冰风光暗”五种属性，即可领取META代币空投。\n \
                    2、如果全套为母，将可领取3倍空投。\n \
                    3、集卡活动总奖池90,000,000META，平均分为4期，每期空投2250万META。\n \
                    4、第1/2/3/4期，每天可领取空投分别为450/360/280/230META，并有+-10%范围内的随机变化。 \n \
                    5、领取空投后，META NFTs可以自由交易。\n \
                    6、每个NFT ID每24小时仅可以搭配使用一次。",

                    "App1Alert1": "请先填写邀请码，再领取空投",

                    "App11Loading" : "正在加载 # {{id}}",

                    "App2InfoSuccess": "孵化成功，稍后会将NFT发放到您的地址，请稍后查收。",
                    "App2ClaimButton": "领取",
                    "App2Health": "生命值",

                    "App2AddTab1": '1. 添加NFT',
                    "App2AddTab2": '2. 添加Meta',
                    "App2AddTab3": '3. 确认孵化',

                    "App2ErrorExists": "当前的卡槽有蛋正在孵化，暂时无法添加新的NFT。",
                    "App2LabelSelectToken": "请选择钱包中的NFT",

                    "App2ButtonNext": "下一步",
                    "App2ButtonApproveNFT": "授权NFT",
                    "App2ButtonApproveMETA": "授权Meta",
                    "App2AddHatchingTip": "孵化过程中，不可中断不可退回， 直至结束。",
                    "App2ConfirmToHatching": "确认孵化",
                    "App2TxSubmit": "交易已提交",
                    "App2AddMetaTip": "消耗 {{meta}} META, 24小时完成",
                }
            },
            // 韩语
            ko: {
                translation: {
                    "AppName": "META NFT 컬렉션",
                    "Alert1": "NFT ID를 슬롯 {{idx}}에 넣어주세요",
                    "Alert2": "NFT {{idx}}는 첫 번째 NFT와 패턴이 다릅니다",
                    "Alert3": "ID {{id}}가 중복되었습니다",
                    "Alert4": "ID {{id}}가 기존 색상과 중복되었습니다",
                    "Alert5": "ID {{id}}가 최근 {{days}}일간 사용되었습니다",
                    "Alert6": "추가 실패, NFT #{{id}}가 이미 파괴되었는지 확인하십시오.",
                    "SubmitSuccess": "제출 성공",
                    "SubmitFail": "제출 실패",

                    "TotalReward": "총 보상",
                    "RewardIssued": "발행된 보상",
                    "EveryReward": "매 보상",


                    "Note1": "META NFT를 수집하고, 동일한 패턴의 6가지 색상을 모두 모아 META 보상을 청구하세요. 모든 세트가 날개가 있는 경우 6 배의 보상을 청구할 수 있습니다.",
                    "Note2": "이 지갑에 수집한 META NFT를 슬롯에 추가하세요.",
                    "Note3": "잘못된 NFT를 추가하면 슬롯을 클릭하여 변경할 수 있습니다.",
                    "CheckButton": "검증 및 META 청구",

                    "Rule1": "1. META NFT를 수집하고, 동일한 패턴의 6가지 색상을 모두 모아 META 보상을 청구하세요.",
                    "Rule2": "2. 모든 세트가 날개가 있는 경우 6 배의 보상을 청구할 수 있습니다.",
                    "Rule3": "3. Web3 지갑이나 블록체인 브라우저에서 6개의 NFT ID를 보고 기록하세요.",
                    "Rule4": "4. NFT ID를 슬롯에 추가하고, 검증 및 GAS 수수료를 지불한 후 보상이 지갑으로 전송됩니다.",
                    "Rule5": "5. 컬렉션 이벤트의 총 상금은 15,000,000 META로, 4개 기간에 고르게 분배되며, 각 기간에 3,750,000 META가 발행됩니다.",
                    "Rule6": "6. 1/2/3/4 기간의 각 청구 금액은 8000/6000/4000/2000 META입니다.",
                    "Rule7": "7. 보상을 청구한 후 META NFT를 자유롭게 거래할 수 있습니다.",
                    "Rule8": "8. 각 NFT ID는 {{days}}일 내에 한 번만 사용할 수 있습니다.",
                    "ResultMessage1": "당신은 받을 ",
                    "ResultMessage2": "Bscscan에서 진행 상황 확인",

                    "ResultMessage3": "블록체인 브라우저에서 거래 내역을 확인할 수 있습니다",
                    "BoardError1": "NFT ID가 없습니다",
                    "BoardError2": "NFT ID {{id}}가 현재 지갑 주소에 속하지 않습니다",
                    "BoardError3": "NFT ID {{id}}가 이미 사용되었습니다. 지정된 시간 이후에 다시 사용하십시오",
                    "BoardError4": "NFT ID {{id}} 확인 실패: ",
                    "BoardError5": "NFT ID를 입력하십시오",
                    "BoardError6": "{{tDays}}일 내에 1회만 사용할 수 있습니다. {{days}}일 {{hours}}시간 {{minutes}}분 후에 NFT #{{id}}를 다시 사용하십시오.",

                    "RuleTitle": "META 컬렉션 규칙",

                    "App11Infomation": "META NFT를 수집하고 '불, 얼음, 바람, 빛, 어둠' 다섯 가지 속성의 전체 세트를 수집하여 META 토큰 에어드랍을 청구하세요. 전체 세트가 모두 모체인 경우, 에어드랍 x3배.",
                    "App11Synthesize": "에어드랍 청구",
                    "App11PlusMetaNFT": "+ META NFTs",

                    "App11Error1": "귀하의 지갑에 조건을 충족하는 NFT가 충분하지 않습니다",
                    "App11Error21": "초대 코드 오류",
                    "App11Prompt1": "초대 코드를 입력하십시오",
                    "App11Label1": "내 초대자",
                    'App11Label2': '초대 코드를 입력하십시오',
                    "App11Label3": '초대 코드를 입력하면 "수집 및 에어드랍 받기"에 참여할 수 있습니다. 입력 후 변경할 수 없습니다.',
                    "App11Label4": "내 초대 코드",
                    "App11Label5": "아직 받지 않음",
                    "App11Tip1": "당신은 받을 수 있습니다",

                    "App11Tip2": "에어드랍을 받은 후 독점적인 초대 코드를 받게되며, 친구를 초대하여 보상을 받을 수 있습니다.",
                    "App11Tip3": "초대 보상 규칙",
                    "App11Tip4": "당신의 초대 코드를 사용하는 계정이 에어드랍을 받으면 10% 보상을 받게 됩니다.",
                    "App11Tip5": "나는 초대했다",
                    "App11InviteButton": "초대",

                    "App11Label6": "확인",
                    "App11Label7": "취소",
                    "App11Label8": "초대 코드를 입력하십시오",

                    "App1Rule": "1. META NFT를 수집하고 '불, 얼음, 바람, 빛, 어둠' 다섯 가지 속성의 전체 세트를 수집하여 META 토큰 에어드랍을 청구하세요.\n \
                    2. 전체 세트가 모두 모체인 경우, 3배의 에어드랍을 청구할 수 있습니다.\n \
                    3. 수집 이벤트의 총 상금은 90,000,000 META로, 4개 기간에 고르게 분배되며, 각 기간에 22,500,000 META가 에어드랍됩니다.\n \
                    4. 1/2/3/4 기간, 매일 에어드랍 금액은 450/360/280/230 META이며, +-10% 범위 내에서 무작위로 변경됩니다.\n \
                    5. 에어드랍을 받은 후, META NFT는 자유롭게 거래할 수 있습니다.\n \
                    6. 각 NFT ID는 24시간마다 한 번씩만 사용할 수 있습니다.",

                    "App1Alert1": "에어드랍을 받기 전에 초대자를 먼저 바인딩하십시오",

                    "App11Loading" : "로드 중 #{{id}}",

                    "App2InfoSuccess": "부화 성공, NFT가 나중에 귀하의 주소로 전송될 예정이니 나중에 확인하십시오.",
                    "App2ClaimButton": "청구",
                    "App2Health": "HP",
                    "App2AddTab1": '1. NF 추가',
                    "App2AddTab2": '2. Meta 추가',
                    "App2AddTab3": '3. 부화 확인',
                    "App2ErrorExists": "현재 슬롯에 부화 중인 알이 있어 새로운 NFT를 추가할 수 없습니다.",
                    "App2LabelSelectToken": "지갑에서 NFT를 선택하십시오",
                    "App2ButtonNext": "다음",
                    "App2ButtonApproveNFT": "NFT 승인",
                    "App2ButtonApproveMETA": "Meta 승인",

                    "App2AddHatchingTip": "부화 과정 중에는 중단하거나 반환할 수 없으며, 끝까지 진행됩니다.",
                    "App2ConfirmToHatching": "부화 확인",
                    "App2TxSubmit": "거래 제출됨",

                    "App2AddMetaTip": "{{meta}} META 소비, 24시간 내에 완료",

                }

            },

            // 日语
            ja: {
                translation: {
                    "AppName": "META NFT コレクション",
                    "Alert1": "スロット{{idx}}にNFT IDを入れてください",
                    "Alert2": "NFT {{idx}}は最初のNFTとパターンが異なります",
                    "Alert3": "ID {{id}}が重複しています",
                    "Alert4": "ID {{id}}が既存の色と重複しています",
                    "Alert5": "ID {{id}}は最近{{days}}日間使用されました",
                    "Alert6": "追加に失敗しました。NFT #{{id}}がすでに破壊されているかどうかを確認してください。",
                    "SubmitSuccess": "提出成功",
                    "SubmitFail": "提出失敗",

                    "TotalReward": "総報酬",
                    "RewardIssued": "発行済み報酬",
                    "EveryReward": "毎報酬",

                    "Note1": "META NFTを集め、同じパターンの6つの色を集めてMETA報酬を請求してください。全セットがすべて羽がある場合、報酬×6倍を請求できます。",
                    "Note2": "このウォレットに収集したMETA NFTをスロットに追加してください。",
                    "Note3": "間違ったNFTを追加した場合、スロットをクリックして変更できます。",
                    "CheckButton": "検証してMETAを請求",

                    "Rule1": "1. META NFTを集め、同じパターンの6つの色を集めてMETA報酬を請求してください。",
                    "Rule2": "2. 全セットが羽がある場合、6倍の報酬を請求できます。",
                    "Rule3": "3. Web3ウォレットまたはブロックチェーンブラウザで6つのNFT IDを表示して記録してください。",
                    "Rule4": "4. NFT IDをスロットに追加し、検証してGAS手数料を支払った後、報酬がウォレットに送信されます。",
                    "Rule5": "5. コレクションイベントの総賞金は15,000,000 METAで、4つの期間に均等に分配され、各期間に3,750,000 METAが発行されます。",
                    "Rule6": "6. 第1/2/3/4期の各請求額はそれぞれ8000/6000/4000/2000 METAです。",
                    "Rule7": "7. 報酬を請求した後、META NFTは自由に取引できます。",
                    "Rule8": "8. 各NFT IDは{{days}}日以内に1回だけ使用できます。",
                    "ResultMessage1": "あなたは受け取る ",
                    "ResultMessage2": "Bscscanで進捗を確認",
                    "ResultMessage3": "ブロックチェーンブラウザで取引を確認できます",
                    "BoardError1": "NFT IDがありません",
                    "BoardError2": "NFT ID {{id}}は現在のウォレットアドレスに属していません",
                    "BoardError3": "NFT ID {{id}}は既に使用されています。指定された時間後に再使用してください",
                    "BoardError4": "NFT ID {{id}}の検証に失敗しました: ",
                    "BoardError5": "NFT IDを入力してください",
                    "BoardError6": "{{tDays}}日以内に1回だけ使用できます。{{days}}日{{hours}}時間{{minutes}}分後にNFT #{{id}}を再使用してください。",

                    "RuleTitle": "META コレクションルール",

                    "App11Infomation": "META NFTを集め、'火、氷、風、光、闇'の5つの属性の完全セットを集めると、METAトークンのエアドロップを請求できます。全セットがすべて母体の場合、エアドロップx3倍。",
                    "App11Synthesize": "エアドロップを請求",
                    "App11PlusMetaNFT": "+ META NFTs",

                    "App11Error1": "お使いのウォレットに条件を満たすNFTが十分にありません",
                    "App11Error21": "招待コードエラー",

                    "App11Prompt1": "招待コードを入力してください",
                    "App11Label1": "私の招待者",
                    'App11Label2': '招待コードを入力してください',

                    "App11Label3": '招待コードを入力すると、「収集してエアドロップを受け取る」に参加できます。入力後は変更できません。',
                    "App11Label4": "私の招待コード",
                    "App11Label5": "まだ取得していません",
                    "App11Tip1": "あなたは受け取ることができます",
                    "App11Tip2": "エアドロップを受け取った後、専用の招待コードを取得し、友達を招待して報酬を受け取ることができます。",
                    "App11Tip3": "招待報酬ルール",
                    "App11Tip4": "あなたの招待コードを使用するアカウントがエアドロップを受け取ると、10%の報酬を受け取ります。",
                    "App11Tip5": "私が招待した",

                    "App11InviteButton": "招待",

                    "App11Label6": "確認",
                    "App11Label7": "キャンセル",
                    "App11Label8": "招待コードを入力してください",

                    "App1Rule": "1. META NFTを集め、'火、氷、風、光、闇'の5つの属性の完全セットを集めると、METAトークンのエアドロップを請求できます。\n \
                    2. 全セットがすべて母体の場合、3倍のエアドロップを請求できます。\n \
                    3. コレクションイベントの総賞金は90,000,000 METAで、4つの期間に均等に分配され、各期間に22,500,000 METAがエアドロップされます。\n \
                    4. 1/2/3/4期、毎日のエアドロップ金額はそれぞれ450/360/280/230 METAで、+-10%の範囲内でランダムに変化します。\n \
                    5. エアドロップを受け取った後、META NFTは自由に取引できます。\n \
                    6. 各NFT IDは24時間ごとに1回だけ使用できます。",

                    "App1Alert1": "エアドロップを受け取る前に招待者をバインドしてください",

                    "App11Loading" : "ロード中 #{{id}}",

                    "App2InfoSuccess": "孵化に成功しました。NFTは後であなたのアドレスに送信される予定ですので、後で確認してください。",
                    "App2ClaimButton": "請求",
                    "App2Health": "HP",
                    "App2AddTab1": '1. NF追加',
                    "App2AddTab2": '2. Meta追加',
                    "App2AddTab3": '3. 孵化確認',
                    "App2ErrorExists": "現在のスロットに孵化中の卵があるため、新しいNFTを追加できません。",
                    "App2LabelSelectToken": "ウォレットからNFTを選択してください",
                    "App2ButtonNext": "次へ",
                    "App2ButtonApproveNFT": "NFT承認",
                    "App2ButtonApproveMETA": "Meta承認",
                    "App2AddHatchingTip": "孵化中は中断できず、最後まで戻すことはできません。",
                    "App2ConfirmToHatching": "孵化確認",
                    "App2TxSubmit": "取引が提出されました",

                    "App2AddMetaTip": "{{meta}} META消費、24時間で完了",

                }
            },

            // 西班牙语

            es: {
                translation: {
                    "AppName": "Colección de META NFT",
                    "Alert1": "Por favor, coloque el ID de NFT en la ranura {{idx}}",
                    "Alert2": "El NFT {{idx}} no es el mismo patrón que el primer NFT",
                    "Alert3": "El ID {{id}} está duplicado",
                    "Alert4": "El ID {{id}} está duplicado con el color existente",
                    "Alert5": "El ID {{id}} se ha utilizado en los últimos {{days}} días",
                    "Alert6": "Error al agregar, por favor verifique si el NFT #{{id}} ha sido destruido.",
                    "SubmitSuccess": "Enviar con éxito",
                    "SubmitFail": "Fracaso al enviar",

                    "TotalReward": "Recompensa total",
                    "RewardIssued": "Recompensas emitidas",
                    "EveryReward": "Cada recompensa",

                    "Note1": "Colecciona META NFTs, colecciona un conjunto completo de 6 colores del mismo patrón para reclamar recompensas META. Si el conjunto completo tiene alas, puedes reclamar 6 veces las recompensas.",
                    "Note2": "Agregue los META NFTs recolectados en esta billetera a la ranura.",

                    "Note3": "Si se agrega el NFT incorrecto, puede hacer clic en la ranura para cambiar.",
                    "CheckButton": "Verificar y reclamar META",

                    "Rule1": "1. Colecciona META NFTs, colecciona un conjunto completo de 6 colores del mismo patrón para reclamar recompensas META.",
                    "Rule2": "2. Si el conjunto completo tiene alas, puedes reclamar 6 veces las recompensas.",

                    "Rule3": "3. Vea y registre 6 NFT ID en una billetera Web3 o un navegador de blockchain.",
                    "Rule4": "4. Agregue el NFT ID a la ranura, verifique y pague la tarifa de GAS, y las recompensas se enviarán a su billetera.",
                    "Rule5": "5. El premio total del evento de colección es de 15,000,000 META, que se distribuye uniformemente en 4 períodos, y se emiten 3,750,000 META en cada período.",
                    "Rule6": "6. La cantidad de cada reclamo en el 1er/2do/3er/4to período es de 8000/6000/4000/2000 META.",
                    "Rule7": "7. Después de reclamar las recompensas, los META NFT se pueden negociar libremente.",
                    "Rule8": "8. Cada NFT ID solo se puede usar una vez dentro de {{days}} días.",
                    "ResultMessage1": "Recibirá ",
                    "ResultMessage2": "Ver progreso en Bscscan",
                    "ResultMessage3": "Puede abrir el navegador de blockchain para ver la transacción",
                    "BoardError1": "El ID de NFT no existe",
                    "BoardError2": "El ID de NFT {{id}} no pertenece a la dirección de billetera actual",
                    "BoardError3": "El ID de NFT {{id}} se ha utilizado, utilícelo nuevamente después del tiempo especificado",
                    "BoardError4": "La verificación del ID de NFT {{id}} falló: ",
                    "BoardError5": "Por favor ingrese el ID de NFT",
                    "BoardError6": "Solo se puede usar una vez dentro de {{tDays}} días. Espere {{days}} días {{hours}} horas {{minutes}} minutos para usar NFT #{{id}} nuevamente.",


                    "RuleTitle": "Reglas de colección de META",

                    "App11Infomation": "Colecciona META NFTs, colecciona un conjunto completo de cinco atributos de 'Fuego, Hielo, Viento, Luz, Oscuridad' para reclamar la entrega de tokens META. Si el conjunto completo es todo madre, la entrega x3 veces.",
                    "App11Synthesize": "Reclamar entrega",

                    "App11PlusMetaNFT": "+ META NFTs",

                    "App11Error1": "No hay suficientes NFT que cumplan con los requisitos en su billetera",
                    "App11Error21": "Código de invitación incorrecto",

                    "App11Prompt1": "Por favor ingrese el código de invitación",
                    "App11Label1": "Mi invitador",
                    'App11Label2': 'Por favor complete el código de invitación',
                    "App11Label3": 'Después de completar el código de invitación, puede participar en "recopilar y recibir la entrega". No se puede cambiar después de completarlo.',
                    "App11Label4": "Mi código de invitación",
                    "App11Label5": "No obtenido",
                    "App11Tip1": "Puede obtener",
                    "App11Tip2": "Después de recibir la entrega, obtendrá un código de invitación exclusivo, que puede invitar a amigos a participar en la recopilación para obtener recompensas.",
                    "App11Tip3": "Reglas de recompensa de invitación",
                    "App11Tip4": "Cuando la cuenta que usa su código de invitación recibe la entrega, recibirá el 10% de la recompensa.",
                    "App11Tip5": "Invité a",

                    "App11InviteButton": "Invitar",
                    "App11Label6": "Confirmar",
                    "App11Label7": "Cancelar",

                    "App11Label8": "Por favor complete el código de invitación",
                    "App1Rule": "1. Colecciona META NFTs, colecciona un conjunto completo de cinco atributos de 'Fuego, Hielo, Viento, Luz, Oscuridad' para reclamar la entrega de tokens META.\n \
                    2. Si el conjunto completo es todo madre, la entrega x3 veces.\n \
                    3. El premio total del evento de colección es de 90,000,000 META, que se distribuye uniformemente en 4 períodos, y se emiten 22,500,000 META en cada período.\n \
                    4. El monto de entrega diario en el 1er/2do/3er/4to período es de 450/360/280/230 META, y varía aleatoriamente dentro del rango de +-10%.\n \
                    5. Después de recibir la entrega, los META NFT se pueden negociar libremente.\n \
                    6. Cada NFT ID solo se puede usar una vez cada 24 horas.",

                    "App1Alert1": "Por favor complete el código de invitación antes de recibir la entrega",

                    "App11Loading" : "Cargando #{{id}}",

                    "App2InfoSuccess": "Incubación exitosa, pronto se enviará el NFT a su dirección, por favor espere.",
                    "App2ClaimButton": "Reclamar",
                    "App2Health": "HP",
                    "App2AddTab1": '1. Agregar NFT',
                    "App2AddTab2": '2. Agregar Meta',
                    "App2AddTab3": '3. Confirmar',
                    "App2ErrorExists": "Actualmente hay un huevo incubando en la ranura, no se puede agregar un nuevo NFT.",
                    "App2LabelSelectToken": "Seleccione el NFT en la billetera",
                    "App2ButtonNext": "Siguiente",
                    "App2ButtonApproveNFT": "Aprobar NFT",
                    "App2ButtonApproveMETA": "Aprobar Meta",
                    "App2AddHatchingTip": "Durante el proceso de incubación, no se puede interrumpir ni devolver, hasta que finalice.",
                    "App2ConfirmToHatching": "Confirmar incubación",

                    "App2AddMetaTip": "Consumir {{meta}} META, completado en 24 horas",


                }
            },
            // 法语
            fr: {
                translation: {
                    "AppName": "Collection de META NFT",
                    "Alert1": "Veuillez placer l'ID de NFT dans la fente {{idx}}",
                    "Alert2": "Le NFT {{idx}} n'est pas le même motif que le premier NFT",
                    "Alert3": "L'ID {{id}} est dupliqué",
                    "Alert4": "L'ID {{id}} est dupliqué avec la couleur existante",
                    "Alert5": "L'ID {{id}} a été utilisé au cours des {{days}} derniers jours",
                    "Alert6": "Échec de l'ajout, veuillez vérifier si le NFT #{{id}} a été détruit.",
                    "SubmitSuccess": "Soumettre avec succès",
                    "SubmitFail": "Échec de la soumission",

                    "TotalReward": "Récompense totale",
                    "RewardIssued": "Récompenses émises",
                    "EveryReward": "Chaque récompense",

                    "Note1": "Collectionnez des META NFT, collectez un ensemble complet de 6 couleurs du même motif pour réclamer des récompenses META. Si l'ensemble complet a des ailes, vous pouvez réclamer 6 fois les récompenses.",
                    "Note2": "Veuillez ajouter les META NFT collectés dans ce portefeuille à la fente.",
                    "Note3": "Si le mauvais NFT est ajouté, vous pouvez cliquer sur la fente pour changer.",
                    "CheckButton": "Vérifier et réclamer META",

                    "Rule1": "1. Collectionnez des META NFT, collectez un ensemble complet de 6 couleurs du même motif pour réclamer des récompenses META.",
                    "Rule2": "2. Si l'ensemble complet a des ailes, vous pouvez réclamer 6 fois les récompenses.",
                    "Rule3": "3. Voir et enregistrer 6 ID NFT dans un portefeuille Web3 ou un navigateur blockchain.",
                    "Rule4": "4. Ajoutez l'ID NFT à la fente, vérifiez et payez les frais de GAS, et les récompenses seront envoyées à votre portefeuille.",
                    "Rule5": "5. Le pool de prix total de l'événement de collection est de 15 000 000 META, réparti uniformément en 4 périodes, et 3,750,000 META sont émis à chaque période.",
                    "Rule6": "6. Le montant de chaque réclamation dans le 1er/2ème/3ème/4ème période est de 8000/6000/4000/2000 META.",
                    "Rule7": "7. Après avoir réclamé les récompenses, les META NFT peuvent être librement échangés.",
                    "Rule8": "8. Chaque ID NFT ne peut être utilisé qu'une seule fois dans les {{days}} jours.",
                    "ResultMessage1": "Vous recevrez ",
                    "ResultMessage2": "Voir la progression sur Bscscan",
                    "ResultMessage3": "Vous pouvez ouvrir le navigateur blockchain pour voir la transaction",
                    "BoardError1": "L'ID NFT n'existe pas",
                    "BoardError2": "L'ID NFT {{id}} n'appartient pas à l'adresse de portefeuille actuelle",
                    "BoardError3": "L'ID NFT {{id}} a été utilisé, veuillez l'utiliser à nouveau après le temps spécifié",
                    "BoardError4": "La vérification de l'ID NFT {{id}} a échoué: ",
                    "BoardError5": "Veuillez entrer l'ID NFT",
                    "BoardError6": "Ne peut être utilisé qu'une seule fois dans les {{tDays}} jours. Veuillez attendre {{days}} jours {{hours}} heures {{minutes}} minutes pour utiliser à nouveau NFT #{{id}}.",

                    "RuleTitle": "Règles de collection META",

                    "App11Infomation": "Collectionnez des META NFT, collectez un ensemble complet de cinq attributs de 'Feu, Glace, Vent, Lumière, Obscurité' pour réclamer une distribution de jetons META. Si l'ensemble complet est tout mère, la distribution x3 fois.",
                    "App11Synthesize": "Réclamer la distribution",
                    "App11PlusMetaNFT": "+ META NFTs",

                    "App11Error1": "Il n'y a pas assez de NFT répondant aux critères dans votre portefeuille",
                    "App11Error21": "Code d'invitation incorrect",

                    "App11Prompt1": "Veuillez entrer le code d'invitation",
                    "App11Label1": "Mon invité",

                    'App11Label2': 'Veuillez remplir le code d\'invitation',
                    "App11Label3": 'Après avoir rempli le code d\'invitation, vous pouvez participer à "collecter et recevoir la distribution". Ne peut pas être modifié après avoir été rempli.',
                    "App11Label4": "Mon code d'invitation",
                    "App11Label5": "Pas encore reçu",

                    "App11Tip1": "Vous pouvez recevoir",
                    "App11Tip2": "Après avoir reçu la distribution, vous recevrez un code d'invitation exclusif, qui peut inviter des amis à participer à la collecte pour obtenir des récompenses.",
                    "App11Tip3": "Règles de récompense d'invitation",
                    "App11Tip4": "Lorsque le compte utilisant votre code d'invitation reçoit la distribution, vous recevrez 10% de récompense.",
                    "App11Tip5": "J'ai invité",

                    "App11InviteButton": "Inviter",

                    "App11Label6": "Confirmer",
                    "App11Label7": "Annuler",
                    "App11Label8": "Veuillez entrer le code d'invitation",

                    "App1Rule": "1. Collectionnez des META NFT, collectez un ensemble complet de cinq attributs de 'Feu, Glace, Vent, Lumière, Obscurité' pour réclamer la distribution de jetons META.\n \
                    2. Si l'ensemble complet est tout mère, la distribution x3 fois.\n \
                    3. Le pool de prix total de l'événement de collection est de 90 000 000 META, réparti uniformément en 4 périodes, et 22,500,000 META sont émis à chaque période.\n \
                    4. Le montant de distribution quotidien dans le 1er/2ème/3ème/4ème période est de 450/360/280/230 META, et varie aléatoirement dans la plage de +-10%.\n \
                    5. Après avoir reçu la distribution, les META NFT peuvent être librement échangés.\n \
                    6. Chaque ID NFT ne peut être utilisé qu'une fois toutes les 24 heures.",

                    "App1Alert1": "Veuillez remplir le code d'invitation avant de recevoir la distribution",

                    "App11Loading" : "Chargement #{{id}}",

                    "App2InfoSuccess": "Incubation réussie, le NFT sera envoyé à votre adresse plus tard, veuillez vérifier plus tard.",
                    "App2ClaimButton": "Réclamer",
                    "App2Health": "HP",
                    "App2AddTab1": '1. Ajouter NFT',
                    "App2AddTab2": '2. Ajouter Meta',
                    "App2AddTab3": '3. Confirmation',
                    "App2ErrorExists": "Il y a actuellement un œuf en incubation dans la fente, vous ne pouvez pas ajouter un nouveau NFT.",
                    "App2LabelSelectToken": "Sélectionnez le NFT dans le portefeuille",
                    "App2ButtonNext": "Suivant",
                    "App2ButtonApproveNFT": "Approuver NFT",
                    "App2ButtonApproveMETA": "Approuver Meta",
                    "App2AddHatchingTip": "Pendant le processus d'incubation, il ne peut pas être interrompu ou retourné, jusqu'à la fin.",
                    "App2ConfirmToHatching": "Confirmation de l'incubation",
                    "App2TxSubmit": "Transaction soumise",

                    "App2AddMetaTip": "Consommer {{meta}} META, terminé en 24 heures",

                }
            },
            de: {
                translation: {
                    "AppName": "META NFT-Sammlung",
                    "Alert1": "Bitte setzen Sie die NFT-ID in den Steckplatz {{idx}}",
                    "Alert2": "NFT {{idx}} ist nicht das gleiche Muster wie das erste NFT",
                    "Alert3": "ID {{id}} ist dupliziert",
                    "Alert4": "ID {{id}} ist mit der vorhandenen Farbe dupliziert",
                    "Alert5": "ID {{id}} wurde in den letzten {{days}} Tagen verwendet",
                    "Alert6": "Hinzufügen fehlgeschlagen, bitte überprüfen Sie, ob das NFT #{{id}} zerstört wurde.",
                    "SubmitSuccess": "Erfolgreich einreichen",
                    "SubmitFail": "Einreichen fehlgeschlagen",

                    "TotalReward": "Gesamtbetrag",
                    "RewardIssued": "Ausgegebene Belohnungen",
                    "EveryReward": "Jede Belohnung",

                    "Note1": "Sammeln Sie META NFTs, sammeln Sie einen kompletten Satz von 6 Farben des gleichen Musters, um META-Belohnungen zu beanspruchen. Wenn der vollständige Satz alle Flügel hat, können Sie 6-mal die Belohnungen beanspruchen.",
                    "Note2": "Bitte fügen Sie die in dieser Brieftasche gesammelten META NFTs dem Steckplatz hinzu.",
                    "Note3": "Wenn das falsche NFT hinzugefügt wird, können Sie auf den Steckplatz klicken, um zu ändern.",
                    "CheckButton": "Überprüfen und META beanspruchen",

                    "Rule1": "1. Sammeln Sie META NFTs, sammeln Sie einen kompletten Satz von 6 Farben des gleichen Musters, um META-Belohnungen zu beanspruchen.",
                    "Rule2": "2. Wenn der vollständige Satz alle Flügel hat, können Sie 6-mal die Belohnungen beanspruchen.",
                    "Rule3": "3. Sehen und notieren Sie 6 NFT-IDs in einer Web3-Brieftasche oder einem Blockchain-Browser.",
                    "Rule4": "4. Fügen Sie die NFT-ID dem Steckplatz hinzu, überprüfen und zahlen Sie die GAS-Gebühr, und die Belohnungen werden an Ihre Brieftasche gesendet.",
                    "Rule5": "5. Der Gesamtpreispool des Sammelereignisses beträgt 15.000.000 META, der gleichmäßig in 4 Perioden verteilt ist, und in jeder Periode werden 3,750,000 META ausgegeben.",
                    "Rule6": "6. Die Höhe jeder Forderung in der 1./2./3./4. Periode beträgt 8000/6000/4000/2000 META.",
                    "Rule7": "7. Nachdem Sie die Belohnungen beansprucht haben, können META NFTs frei gehandelt werden.",
                    "Rule8": "8. Jede NFT-ID kann innerhalb von {{days}} Tagen nur einmal verwendet werden.",
                    "ResultMessage1": "Sie erhalten ",
                    "ResultMessage2": "Fortschritt auf Bscscan anzeigen",
                    "ResultMessage3": "Sie können den Blockchain-Browser öffnen, um die Transaktion anzuzeigen",
                    "BoardError1": "NFT-ID existiert nicht",
                    "BoardError2": "NFT-ID {{id}} gehört nicht zur aktuellen Brieftaschenadresse",
                    "BoardError3": "NFT-ID {{id}} wurde bereits verwendet, verwenden Sie sie nach der angegebenen Zeit erneut",
                    "BoardError4": "NFT-ID {{id}} Überprüfung fehlgeschlagen: ",
                    "BoardError5": "Bitte geben Sie die NFT-ID ein",
                    "BoardError6": "Kann innerhalb von {{tDays}} Tagen nur einmal verwendet werden. Bitte warten Sie {{days}} Tage {{hours}} Stunden {{minutes}} Minuten, um NFT #{{id}} erneut zu verwenden.",

                    "RuleTitle": "META-Sammelregeln",

                    "App11Infomation": "Sammeln Sie META NFTs, sammeln Sie einen kompletten Satz von fünf Attributen 'Feuer, Eis, Wind, Licht, Dunkelheit', um einen META-Token-Airdrop zu beanspruchen. Wenn der vollständige Satz alles Mutter ist, der Airdrop x3 Mal.",
                    "App11Synthesize": "Airdrop beanspruchen",
                    "App11PlusMetaNFT": "+ META NFTs",

                    "App11Error1": "Es gibt nicht genügend NFTs, die die Anforderungen in Ihrer Brieftasche erfüllen",
                    "App11Error21": "Falscher Einladungscode",

                    "App11Prompt1": "Bitte geben Sie den Einladungscode ein",
                    "App11Label1": "Mein Einlader",
                    'App11Label2': 'Bitte füllen Sie den Einladungscode aus',
                    "App11Label3": 'Nach dem Ausfüllen des Einladungscodes können Sie an "Sammeln und Empfangen des Airdrops" teilnehmen. Kann nach dem Ausfüllen nicht geändert werden.',
                    "App11Label4": "Mein Einladungscode",
                    "App11Label5": "Noch nicht erhalten",
                    "App11Tip1": "Sie können erhalten",
                    "App11Tip2": "Nach Erhalt des Airdrops erhalten Sie einen exklusiven Einladungscode, mit dem Sie Freunde einladen können, um Belohnungen zu erhalten.",
                    "App11Tip3": "Einladungsbelohnungsregeln",
                    "App11Tip4": "Wenn das Konto, das Ihren Einladungscode verwendet, den Airdrop erhält, erhalten Sie 10% Belohnung.",

                    "App11Tip5": "Ich habe eingeladen",
                    "App11InviteButton": "Einladen",

                    "App11Label6": "Bestätigen",
                    "App11Label7": "Stornieren",
                    "App11Label8": "Bitte geben Sie den Einladungscode ein",

                    "App1Rule": "1. Sammeln Sie META NFTs, sammeln Sie einen kompletten Satz von fünf Attributen 'Feuer, Eis, Wind, Licht, Dunkelheit', um einen META-Token-Airdrop zu beanspruchen.\n \
                    2. Wenn der vollständige Satz alles Mutter ist, der Airdrop x3 Mal.\n \
                    3. Der Gesamtpreispool des Sammelereignisses beträgt 90.000.000 META, der gleichmäßig in 4 Perioden verteilt ist, und in jeder Periode werden 22.500.000 META ausgegeben.\n \
                    4. Der tägliche Airdrop-Betrag in der 1./2./3./4. Periode beträgt 450/360/280/230 META und ändert sich zufällig im Bereich von +-10%.\n \
                    5. Nach Erhalt des Airdrops können META NFTs frei gehandelt werden.\n \
                    6. Jede NFT-ID kann nur einmal alle 24 Stunden verwendet werden.",

                    "App1Alert1": "Bitte füllen Sie den Einladungscode aus, bevor Sie den Airdrop erhalten",
                    "App11Loading" : "Laden #{{id}}",

                    "App2InfoSuccess": "Erfolgreiche Inkubation, der NFT wird später an Ihre Adresse gesendet, bitte warten Sie.",
                    "App2ClaimButton": "Anspruch",
                    "App2Health": "HP",
                    "App2AddTab1": '1. NF hinzufügen',
                    "App2AddTab2": '2. Meta hinzufügen',
                    "App2AddTab3": '3. Inkubationsbestätigung',
                    "App2ErrorExists": "Derzeit befindet sich ein Ei in der Inkubationsschlitze, Sie können kein neues NFT hinzufügen.",
                    "App2LabelSelectToken": "Wählen Sie das NFT in der Brieftasche aus",
                    "App2ButtonNext": "Weiter",
                    "App2ButtonApproveNFT": "NFT genehmigen",
                    "App2ButtonApproveMETA": "Meta genehmigen",
                    "App2AddHatchingTip": "Während des Inkubationsprozesses kann nicht unterbrochen oder zurückgegeben werden, bis er abgeschlossen ist.",
                    "App2ConfirmToHatching": "Inkubationsbestätigung",
                    
                    "App2AddMetaTip": "{{meta}} META verbrauchen, in 24 Stunden abgeschlossen",

                }
            }
        },

    });
export default i18n;