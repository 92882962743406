import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


import reportWebVitals from './reportWebVitals';
import App12Page from './pages/app1/App12Page';
import InvitePage from './pages/app1/InvitePage';
import App3Page from './pages/app3/App3Page';
import App3AddNFTPage from './pages/app3/App3AddNFTPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/collect" element={<App12Page />}/>
      <Route path="/invite" element={<InvitePage />}/>
      <Route path="/" element={<App3Page />}/>
      <Route path="/addNFT" element={<App3AddNFTPage />}/>
  </Routes>
  </BrowserRouter>
);

reportWebVitals();
